import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from '../../../../../middlewares/authentication/services/authentication.service';
import {IEmployee} from '../../../../../bundles/erp/bundles/employee/models/employee.model';
import {EmployeeHttpService} from '../../../../../bundles/erp/bundles/employee/services/http/employee-http.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout-form.component.html',
  styleUrls: ['./logout-form.component.scss']
})
export class LogoutFormComponent implements OnInit {

  employee: IEmployee;

  constructor(
    private authenticationService: AuthenticationService,
    private employeeHttpService: EmployeeHttpService
  ) {
  }

  ngOnInit() {
    this.authenticationService.authentication.subscribe(authentication => {
      if (!authentication) {
        return;
      }

      this.employeeHttpService.findByAuthenticationUserId(authentication.session_user_id).subscribe(employee => {
        this.employee = employee;
      });
    });
  }

  onLogoutClickButton() {
    this.authenticationService.logout();
  }
}
