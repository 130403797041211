/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./card-wrapper.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./card-wrapper.component";
var styles_CardWrapperComponent = [i0.styles];
var RenderType_CardWrapperComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CardWrapperComponent, data: {} });
export { RenderType_CardWrapperComponent as RenderType_CardWrapperComponent };
function View_CardWrapperComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "box-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h4", [["class", "box-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 2, 0, currVal_0); }); }
export function View_CardWrapperComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "box"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CardWrapperComponent_1)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "box-body"]], null, null, null, null, null)), i1.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 4, 0, currVal_0); }, null); }
export function View_CardWrapperComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-card-wrapper", [], null, null, null, View_CardWrapperComponent_0, RenderType_CardWrapperComponent)), i1.ɵdid(1, 114688, null, 0, i3.CardWrapperComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CardWrapperComponentNgFactory = i1.ɵccf("app-card-wrapper", i3.CardWrapperComponent, View_CardWrapperComponent_Host_0, { title: "title" }, {}, ["*"]);
export { CardWrapperComponentNgFactory as CardWrapperComponentNgFactory };
