import {Component, OnInit, Renderer2} from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  open = false;

  constructor(private renderer: Renderer2) {
  }

  ngOnInit() {

  }

  onToggleSidebar(evt) {
    evt.preventDefault();

    this.open = !this.open;

    if (this.open) {
      this.renderer.addClass(document.body, 'sidebar-collapse');
    } else {
      this.renderer.removeClass(document.body, 'sidebar-collapse');
    }
  }

}
