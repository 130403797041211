<header class="main-header">
  <div class="d-flex align-items-center logo-box justify-content-between">
    <a (click)="onToggleSidebar($event)" class="waves-effect waves-light nav-link rounded mx-10 push-btn"
       data-toggle="push-menu" href="#" role="button">
      <i class="ti-menu"></i>
    </a>
    <!-- Logo -->
    <a class="logo" routerLink="/">
      <img alt="Ecovinal" src="../../../../assets/img/logo.png">
    </a>
  </div>
</header>
