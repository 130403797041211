import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree} from '@angular/router';
import {Observable, of} from 'rxjs';
import {take, tap} from 'rxjs/operators';
import {AuthenticationService} from '../services/authentication.service';
import {SnackBarService} from '../../../services/snack-bar.service';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationGuard implements CanActivate, CanLoad {

  constructor(private authenticationService: AuthenticationService,
              private router: Router,
              private notifier: SnackBarService) {
  }

  // CanActivate is called when accessing a specific route
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (next.data.permission[0] === 'UPDATE_USER' && this.isCurrentUser(next)) {
      return true;
    }
    return this.checkPermission(next);
  }

  // CanLoad will prevent loading a module for lazy loaded modules -> 'loadChildren: ...'
  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return this.checkPermission(route);
  }

  private isCurrentUser(next: ActivatedRouteSnapshot) {
    return +next.params.id === this.authenticationService.authentication.getValue().access_user_id;
  }

  private checkPermission(next: ActivatedRouteSnapshot | Route) {
    return this.authenticationService.hasPermission(next.data.permission).pipe(
      take(1),
      tap(authorized => {
        if (!authorized) {
          this.notifier.showError('Error de autorización, no está autorizado para acceder al recurso');
          this.router.navigate(['/']);
        }
      })
    );
  }
}
