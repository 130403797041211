import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PermissionDirective} from './directives/permission.directive';
import {RoleDirective} from './directives/role.directive';


@NgModule({
  declarations:
    [
      PermissionDirective,
      RoleDirective
    ],
  imports: [
    CommonModule
  ],
  exports: [PermissionDirective, RoleDirective],
  providers: []
})
export class AuthenticationModule {
}
