import { MatPaginatorIntl } from '@angular/material/paginator';
const spanishRangeLabel = (page, pageSize, length) => {
    // tslint:disable-next-line:triple-equals
    if (length == 0 || pageSize == 0) {
        return $localize `0 de ${length}`;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ?
        Math.min(startIndex + pageSize, length) :
        startIndex + pageSize;
    return $localize `${startIndex + 1} - ${endIndex} de ${length}`;
};
const ɵ0 = spanishRangeLabel;
export function getSpanishPaginatorIntl() {
    const paginatorIntl = new MatPaginatorIntl();
    paginatorIntl.itemsPerPageLabel = $localize `Elementos por página:`;
    paginatorIntl.nextPageLabel = $localize `Siguiente página`;
    paginatorIntl.previousPageLabel = $localize `Página anterior`;
    paginatorIntl.getRangeLabel = spanishRangeLabel;
    return paginatorIntl;
}
export { ɵ0 };
