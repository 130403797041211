import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PageHeaderComponent} from './utils/page-header/page-header.component';
import {CardWrapperComponent} from './utils/card-wrapper/card-wrapper.component';
import {RouterModule} from '@angular/router';
import {GroupedProductAutocompleteUtilComponent} from './utils/grouped-product-autocomplete-util/grouped-product-autocomplete-util.component';
import {MatAutocompleteModule, MatFormFieldModule, MatInputModule} from '@angular/material';
import {ReactiveFormsModule} from '@angular/forms';
import {BatchAutocompleteUtilComponent} from './utils/batch-autocomplete-util/batch-autocomplete-util.component';
import {CommandAutocompleteUtilComponent} from './utils/command-autocomplete-util/command-autocomplete-util.component';
import {CompanyAutocompleteUtilComponent} from './utils/company-autocomplete-util/company-autocomplete-util.component';
import {MaterialAutocompleteUtilComponent} from './utils/material-autocomplete-util/material-autocomplete-util.component';
import {PalletSelectableUtilComponent} from './utils/pallet-selectable-util/pallet-selectable-util.component';
import {MatSelectModule} from '@angular/material/select';
import {SalesInvoiceAutocompleteUtilComponent} from './utils/invoice-autocomplete-util/sales-invoice-autocomplete-util.component';
import {AccountNumberAutocompleteUtilComponent} from './utils/account-number-autocomplete-util/components/account-number-selectable.component';
import {PurchaseInvoiceAutocompleteUtilComponent} from './utils/purchase-invoice-autocomplete-util/purchase-invoice-autocomplete-util.component';
import {CurrencyConversionRateUtilComponent} from './utils/currency-conversion-rate-util/currency-conversion-rate-util.component';
import {OriginFormatAutocompleteUtilComponent} from './utils/origin-format-autocomplete-util/origin-format-autocomplete-util.component';
import {TargetFormatAutocompleteUtilComponent} from './utils/target-format-autocomplete-util/target-format-autocomplete-util.component';
import {TankAutocompleteUtilComponent} from './utils/tank-autocomplete-util/tank-autocomplete-util.component';

@NgModule({
  declarations: [
    PageHeaderComponent,
    CardWrapperComponent,
    GroupedProductAutocompleteUtilComponent,
    BatchAutocompleteUtilComponent,
    CommandAutocompleteUtilComponent,
    CompanyAutocompleteUtilComponent,
    MaterialAutocompleteUtilComponent,
    PalletSelectableUtilComponent,
    SalesInvoiceAutocompleteUtilComponent,
    AccountNumberAutocompleteUtilComponent,
    PurchaseInvoiceAutocompleteUtilComponent,
    CurrencyConversionRateUtilComponent,
    OriginFormatAutocompleteUtilComponent,
    TargetFormatAutocompleteUtilComponent,
    TankAutocompleteUtilComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
  ],
  exports: [
    PageHeaderComponent,
    CardWrapperComponent,
    GroupedProductAutocompleteUtilComponent,
    BatchAutocompleteUtilComponent,
    CommandAutocompleteUtilComponent,
    CompanyAutocompleteUtilComponent,
    MaterialAutocompleteUtilComponent,
    PalletSelectableUtilComponent,
    SalesInvoiceAutocompleteUtilComponent,
    AccountNumberAutocompleteUtilComponent,
    PurchaseInvoiceAutocompleteUtilComponent,
    CurrencyConversionRateUtilComponent,
    OriginFormatAutocompleteUtilComponent,
    TargetFormatAutocompleteUtilComponent,
    TankAutocompleteUtilComponent,
  ],
})
export class ComponentsModule {
}
