import { Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../services/authentication.service";
import * as i2 from "@angular/router";
import * as i3 from "../../../services/snack-bar.service";
export class AuthorizationGuard {
    constructor(authenticationService, router, notifier) {
        this.authenticationService = authenticationService;
        this.router = router;
        this.notifier = notifier;
    }
    // CanActivate is called when accessing a specific route
    canActivate(next, state) {
        if (next.data.permission[0] === 'UPDATE_USER' && this.isCurrentUser(next)) {
            return true;
        }
        return this.checkPermission(next);
    }
    // CanLoad will prevent loading a module for lazy loaded modules -> 'loadChildren: ...'
    canLoad(route, segments) {
        return this.checkPermission(route);
    }
    isCurrentUser(next) {
        return +next.params.id === this.authenticationService.authentication.getValue().access_user_id;
    }
    checkPermission(next) {
        return this.authenticationService.hasPermission(next.data.permission).pipe(take(1), tap(authorized => {
            if (!authorized) {
                this.notifier.showError('Error de autorización, no está autorizado para acceder al recurso');
                this.router.navigate(['/']);
            }
        }));
    }
}
AuthorizationGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthorizationGuard_Factory() { return new AuthorizationGuard(i0.ɵɵinject(i1.AuthenticationService), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.SnackBarService)); }, token: AuthorizationGuard, providedIn: "root" });
