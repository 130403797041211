import {Directive, Input, OnDestroy, TemplateRef, ViewContainerRef} from '@angular/core';
import {AuthenticationService} from '../services/authentication.service';
import {Subscription} from 'rxjs';

@Directive({
  selector: '[appRole]'
})
export class RoleDirective implements OnDestroy {

  subscription: Subscription;

  constructor(private authService: AuthenticationService,
              private templateRef: TemplateRef<any>,
              private vcRef: ViewContainerRef) {
  }

  @Input('appRole') set hasRole(role: string | string[]) {
    const argument = Array.isArray(role) ? role : [role];
    this.subscription = this.authService.hasRole(argument)
      .subscribe(authorized => {
        if (authorized) {
          this.vcRef.clear();
          this.vcRef.createEmbeddedView(this.templateRef);
        } else {
          this.vcRef.clear();
        }
      });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
