
const apiUrl = '%API_PROTOCOL%://%API_URL%/';

export const environment = {
  production: true,
  defaultPageSize: 10,
  apiUrl,
  silicieApiUrl: apiUrl + 'silicie/',
  industriaApiUrl: apiUrl + 'industria/',
  erpApiUrl: apiUrl + 'erp/',
  auditoriaApiUrl: apiUrl + 'auditoria/',
  authApiUrl: apiUrl + 'uaa/',
  stickersUrl: apiUrl + 'stickers/',
  fixedControlIds: {
    bottleBatchControl: 2053,
    bottleVolumeControl: 2052
  }
};
