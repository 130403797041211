import {HttpParams} from '@angular/common/http';

export const createRequestOption = (req?: any): HttpParams => {
  let options: HttpParams = new HttpParams();

  if (req) {
    Object.keys(req).forEach(key => {
      if (key !== 'sort') {
        if (Array.isArray(req[key])) {
          req[key].forEach(value => {
            if (value || value === 0 || value === false) {
              options = options.append(key, convertTypes(value));
            }
          });
        } else {
          if (req[key] || req[key] === 0 || req[key] === false) {
            options = options.set(key, convertTypes(req[key]));
          }
        }
      }
    });

    if (req.sort) {
      req.sort.forEach((val: string) => {
        options = options.append('sort', val);
      });
    }
  }

  return options;
};


function convertTypes(object) {
  if (object instanceof Date) {
    return object.toISOString();
  } else {
    return object;
  }
}
