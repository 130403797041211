import {NgModule} from '@angular/core';
import {AppLayoutComponent} from './app-layout/app-layout.component';
import {RouterModule} from '@angular/router';
import {HeaderComponent} from './app-layout/components/utils/header/header.component';
import {SidebarComponent} from './app-layout/components/utils/sidebar/sidebar.component';
import {LogoutFormComponent} from './app-layout/components/utils/logout-form/logout-form.component';
import {AuthenticationModule} from '../middlewares/authentication/authentication.module';
import {CommonModule} from '@angular/common';
import {MatIconModule, MatInputModule, MatProgressSpinnerModule, MatTooltipModule} from '@angular/material';
import {LoginLayoutComponent} from './login-layout/login-layout.component';
import {LoginFormComponent} from './login-layout/components/utils/login-form/login-form.component';
import {ReactiveFormsModule} from '@angular/forms';
import {DashboardPageComponent} from './app-layout/components/pages/dashboard-page/dashboard-page.component';
import {ComponentsModule} from '../components/components.module';
import {SidebarHeaderComponent} from './app-layout/components/utils/sidebar-header/sidebar-header.component';

@NgModule({
  declarations: [
    AppLayoutComponent,
    LoginLayoutComponent,
    HeaderComponent,
    SidebarComponent,
    LogoutFormComponent,
    LoginFormComponent,
    DashboardPageComponent,
    SidebarHeaderComponent
  ],
  imports: [
    RouterModule,
    AuthenticationModule,
    CommonModule,
    MatIconModule,
    MatTooltipModule,
    MatInputModule,
    MatProgressSpinnerModule,
    ReactiveFormsModule,
    ComponentsModule
  ],
  exports: []
})
export class LayoutsModule {
}
