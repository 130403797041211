import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { createRequestOption } from '../../../../helpers/request-util';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class AuthenticationHttpService {
    constructor(httpClient) {
        this.httpClient = httpClient;
        this.resourceUrl = environment.authApiUrl + 'api/v0';
    }
    login(email, password) {
        return this.httpClient.post(`${this.resourceUrl}/oauth2/login`, { email, password });
    }
    logout(sessionToken) {
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }), body: { session_token: sessionToken }
        };
        return this.httpClient.delete(`${this.resourceUrl}/oauth2/logout`, httpOptions);
    }
    createUser(user) {
        return this.httpClient.post(`${this.resourceUrl}/users`, user);
    }
    lockUser(userId) {
        return this.httpClient.put(`${this.resourceUrl}/users/${userId}/lock`, {});
    }
    unlockUser(userId) {
        return this.httpClient.put(`${this.resourceUrl}/users/${userId}/unlock`, {});
    }
    queryUsers(req) {
        const options = createRequestOption(req);
        return this.httpClient.get(`${this.resourceUrl}/users/search`, { params: options });
    }
    updateUserRoles(userId, roleIds) {
        return this.httpClient.put(`${this.resourceUrl}/users/${userId}/roles`, { role_ids: roleIds });
    }
    addUserRoles(userId, roleIds) {
        return this.httpClient.post(`${this.resourceUrl}/users/${userId}/roles`, { roleIds });
    }
    removeUserRoles(userId, roleIds) {
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' }), body: { roleIds }
        };
        return this.httpClient.delete(`${this.resourceUrl}/users/${userId}/roles`, httpOptions);
    }
    queryRoles(req) {
        const options = createRequestOption(req);
        return this.httpClient.get(`${this.resourceUrl}/roles/search`, { params: options });
    }
    updatePassword(userId, newPassword) {
        return this.httpClient.put(`${this.resourceUrl}/users/${userId}/password`, newPassword);
    }
    updateAccess(authentication) {
        return this.httpClient.post(`${this.resourceUrl}/oauth2/access`, {
            session_token: authentication.session_token,
            roles: authentication.access_user_roles,
            scopes: authentication.access_user_permissions
        });
    }
}
AuthenticationHttpService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthenticationHttpService_Factory() { return new AuthenticationHttpService(i0.ɵɵinject(i1.HttpClient)); }, token: AuthenticationHttpService, providedIn: "root" });
