import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
export class LoginFormComponent {
    constructor(fb, authenticationService, router, snackBar) {
        this.fb = fb;
        this.authenticationService = authenticationService;
        this.router = router;
        this.snackBar = snackBar;
        this.error = false;
        this.loading = true;
    }
    ngOnInit() {
        this.initForm();
        this.loading = false;
    }
    onSubmit() {
        this.submitForm();
    }
    onCloseErrorClick() {
        this.closeError();
    }
    initForm() {
        this.loginForm = this.fb.group({
            user: ['', [Validators.required, Validators.email]],
            password: ['', [Validators.required]]
        });
    }
    submitForm() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const email = this.loginForm.get('user').value;
            const password = this.loginForm.get('password').value;
            this.authenticationService.login(email, password)
                .subscribe(_ => this.router.navigateByUrl('/'), _ => this.snackBar.showError('Correo electrónico o contraseña invalidos.'));
        });
    }
    closeError() {
        this.error = false;
    }
}
