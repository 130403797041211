<!-- Left side column. contains the logo and sidebar -->
<aside class="main-sidebar">

  <!-- new sidebar-->
  <section *ngIf="isLoggedIn()" class="sidebar">

    <ul class="sidebar-menu tree" data-widget="tree">

      <!-- ERP-->
      <ng-container *appPermission="'READ_ERP'">
        <app-sidebar-header
          [expand]="headerVisible.erp"
          (click)="onToggleHeader('erp')">
          ERP
        </app-sidebar-header>

        <li class="treeview" routerLinkActive="active" [style.display]="headerVisible.erp ? 'block' : 'none'">
          <a (click)="onToggleMenu($event, 'menu-open')">
            <i class="fa fa-file-text-o"></i> <span>Compras</span>
            <span class="float-right mr-2"><i class="fa fa-angle-right"></i></span>
          </a>

          <ul class="treeview-menu">
            <li routerLinkActive="active" [style.display]="headerVisible.erp ? 'block' : 'none'">
              <a routerLink="/resources/purchase-orders">
                <i class="fa fa-file-text-o"></i><span>Pedidos de compra</span>
              </a>
            </li>

            <li routerLinkActive="active" [style.display]="headerVisible.erp ? 'block' : 'none'">
              <a routerLink="/resources/delivery-notes">
                <i class="fa fa-list"></i><span>Albaranes</span>
              </a>
            </li>

            <li routerLinkActive="active" [style.display]="headerVisible.erp ? 'block' : 'none'">
              <a routerLink="/resources/purchase-invoices">
                <i class="fa fa-file-text-o"></i><span>Facturas de compra</span>
              </a>
            </li>

            <li routerLinkActive="active" [style.display]="headerVisible.erp ? 'block' : 'none'">
              <a routerLink="/resources/purchase-order-refunds">
                <i class="fa fa-file-text-o"></i><span>Devoluciones de compra</span>
              </a>
            </li>

            <li routerLinkActive="active" [style.display]="headerVisible.erp ? 'block' : 'none'">
              <a routerLink="/resources/purchase-invoice-amends">
                <i class="fa fa-file-text-o"></i><span>Abonos de compra</span>
              </a>
            </li>

            <li routerLinkActive="active" [style.display]="headerVisible.erp ? 'block' : 'none'">
              <a routerLink="/resources/suppliers">
                <i class="fa fa-building"></i><span>Proveedores</span>
              </a>
            </li>

            <li routerLinkActive="active" [style.display]="headerVisible.erp ? 'block' : 'none'">
              <a routerLink="/resources/logistic-suppliers">
                <i class="fa ti-truck"></i><span>Proveedores logísticos</span>
              </a>
            </li>

            <li routerLinkActive="active" [style.display]="headerVisible.erp ? 'block' : 'none'">
              <a routerLink="/resources/logistic-services">
                <i class="fa ti-cloud"></i><span>Servicios logísticos</span>
              </a>
            </li>
          </ul>
        </li>

        <li class="treeview" routerLinkActive="active" [style.display]="headerVisible.erp ? 'block' : 'none'">
          <a (click)="onToggleMenu($event, 'menu-open')">
            <i class="fa fa-file-text"></i> <span>Ventas</span>
            <span class="float-right mr-2"><i class="fa fa-angle-right"></i></span>
          </a>

          <ul class="treeview-menu">
            <li routerLinkActive="active" [style.display]="headerVisible.erp ? 'block' : 'none'">
              <a routerLink="/resources/sales-orders">
                <i class="fa fa-file-text"></i> <span>Pedidos de venta</span>
              </a>
            </li>

            <li routerLinkActive="active" [style.display]="headerVisible.erp ? 'block' : 'none'">
              <a routerLink="/resources/packing-lists">
                <i class="fa fa-list"></i> <span>Packing list</span>
              </a>
            </li>

            <li routerLinkActive="active" [style.display]="headerVisible.erp ? 'block' : 'none'">
              <a routerLink="/resources/sales-invoices">
                <i class="fa fa-file-text-o"></i> <span>Facturas de venta</span>
              </a>
            </li>

<!--            <li routerLinkActive="active" [style.display]="headerVisible.erp ? 'block' : 'none'">-->
<!--              <a routerLink="/resources/sales-invoice-rectifications">-->
<!--                <i class="fa fa-file-text-o"></i> <span>Rectificaciones de venta</span>-->
<!--              </a>-->
<!--            </li>-->

            <li routerLinkActive="active" [style.display]="headerVisible.erp ? 'block' : 'none'">
              <a routerLink="/resources/sales-invoice-amends">
                <i class="fa fa-file-text-o"></i> <span>Abonos de venta</span>
              </a>
            </li>

            <li routerLinkActive="active" [style.display]="headerVisible.erp ? 'block' : 'none'">
              <a routerLink="/resources/clients">
                <i class="fa fa-building-o"></i> <span>Clientes</span>
              </a>
            </li>
          </ul>
        </li>

        <li class="treeview" routerLinkActive="active" [style.display]="headerVisible.erp ? 'block' : 'none'">
          <a (click)="onToggleMenu($event, 'menu-open')">
            <i class="ti-settings"></i> <span>Parámetros</span>
            <span class="float-right mr-2"><i class="fa fa-angle-right"></i></span>
          </a>

          <ul class="treeview-menu">
            <li routerLinkActive="active" [style.display]="headerVisible.erp ? 'block' : 'none'">
              <a routerLink="/resources/parameters/incoterms"><i class="ti-more"></i>Incoterms</a>
            </li>

            <li routerLinkActive="active" [style.display]="headerVisible.erp ? 'block' : 'none'">
              <a routerLink="/resources/parameters/payment-methods"><i class="ti-more"></i>Métodos de pago</a>
            </li>

            <li routerLinkActive="active" [style.display]="headerVisible.erp ? 'block' : 'none'">
              <a routerLink="/resources/parameters/tax-groups"><i class="ti-more"></i>Grupos contables</a>
            </li>

            <li routerLinkActive="active" [style.display]="headerVisible.erp ? 'block' : 'none'">
              <a routerLink="/resources/parameters/transport-modes"><i class="ti-more"></i>Modos de transporte</a>
            </li>

            <li routerLinkActive="active" [style.display]="headerVisible.erp ? 'block' : 'none'">
              <a routerLink="/resources/parameters/warehouse-codes"><i class="ti-more"></i>Códigos de almacén</a>
            </li>

            <li routerLinkActive="active" [style.display]="headerVisible.erp ? 'block' : 'none'">
              <a routerLink="/resources/parameters/indirect-costs"><i class="ti-more"></i>Coste indirecto unitario/hora</a>
            </li>

            <li routerLinkActive="active" [style.display]="headerVisible.erp ? 'block' : 'none'">
              <a routerLink="/resources/parameters/documental-requirements"><i class="ti-more"></i>Requisitos
                documentales</a>
            </li>
          </ul>
        </li>

        <li routerLinkActive="active" [style.display]="headerVisible.erp ? 'block' : 'none'">
          <a routerLink="/resources/warehouses-report">
            <i class="fa fa-cubes"></i> <span>Valoración económica del stock</span>
          </a>
        </li>
      </ng-container>

      <!-- BI -->
      <ng-container *appPermission="'READ_BI'">
        <app-sidebar-header
          [expand]="headerVisible.bi"
          (click)="onToggleHeader('bi')">BI
        </app-sidebar-header>

        <li routerLinkActive="active" [style.display]="headerVisible.bi ? 'block' : 'none'">
          <a routerLink="/bi/dashboard">
            <i class="ti-dashboard"></i> <span>Dashboard</span>
          </a>
        </li>
      </ng-container>
      

      <!-- Silicie -->
      <ng-container *appPermission="'READ_SILICIE'">
        <app-sidebar-header
          [expand]="headerVisible.silicie"
          (click)="onToggleHeader('silicie')">SILICIE
        </app-sidebar-header>

        <li routerLinkActive="active" [style.display]="headerVisible.silicie ? 'block' : 'none'">
          <a routerLink="/silicie/seat">
            <i class="ti-layout-list-thumb-alt"></i> <span>Asientos</span>
          </a>
        </li>

        <li routerLinkActive="active" [style.display]="headerVisible.silicie ? 'block' : 'none'">
          <a routerLink="/silicie/iesa">
            <i class="ti-layout-accordion-merged"></i> <span>IESA</span>
          </a>
        </li>
      </ng-container>

      <!-- Industria -->
      <ng-container
        *appPermission="['READ_INDUSTRY', 'READ_INDUSTRY_CONTROL', 'READ_INDUSTRY_INCIDENT', 'READ_INDUSTRY_PLANNER_VISUALIZATION']">
        <app-sidebar-header
          [expand]="headerVisible.industria"
          (click)="onToggleHeader('industria')">
          Industria
        </app-sidebar-header>

        <li *appPermission="'READ_INDUSTRY_PLANNER_VISUALIZATION'"
            routerLinkActive="active"
            [style.display]="headerVisible.industria ? 'block' : 'none'">
          <a routerLink="/industria/planner/view">
            <i class="fa fa-bar-chart-o"></i> <span>Visualizar planificación</span>
          </a>
        </li>

        <li *appPermission="'READ_INDUSTRY_COMMAND'"
            routerLinkActive="active"
            [style.display]="headerVisible.industria ? 'block' : 'none'">
          <a routerLink="/industria/commands">
            <i class="ti-notepad"></i> <span>Ordenes de producción</span>
          </a>
        </li>

        <li *appPermission="'READ_INDUSTRY_CONTROL'"
            routerLinkActive="active"
            [style.display]="headerVisible.industria ? 'block' : 'none'">
          <a routerLink="/industria/controls">
            <i class="ti-agenda"></i> <span>Registro de controles</span>
          </a>
        </li>

        <li *appPermission="'READ_INDUSTRY_CONTROL_TEMPLATE'"
            routerLinkActive="active"
            [style.display]="headerVisible.industria ? 'block' : 'none'">
          <a routerLink="/industria/control-types">
            <i class="ti-stamp"></i> <span>Plantillas de control</span>
          </a>
        </li>

        <li *appPermission="'READ_INDUSTRY_INCIDENT'"
            routerLinkActive="active"
            [style.display]="headerVisible.industria ? 'block' : 'none'">
          <a routerLink="/industria/incidences">
            <i class="fa fa-exclamation-triangle"></i> <span>Incidencias</span>
          </a>
        </li>

        <li *appPermission="'READ_INDUSTRY_SENSOR'"
            class="treeview"
            routerLinkActive="active"
            [style.display]="headerVisible.industria ? 'block' : 'none'">
          <a (click)="onToggleMenu($event, 'menu-open')">
            <i class="ti-panel"></i> <span>Sensórica depositos</span>
            <span class="float-right mr-2"><i class="fa fa-angle-right"></i></span>
          </a>
          <ul class="treeview-menu">
            <li routerLinkActive="active" [style.display]="headerVisible.resources ? 'block' : 'none'">
              <a routerLink="/resources/tank-sensor-groups"><i class="ti-more"></i>Concentradores de datos</a>
            </li>
            <li routerLinkActive="active" [style.display]="headerVisible.resources ? 'block' : 'none'">
              <a routerLink="/resources/tank-sensors"><i class="ti-more"></i>Sensores de nivel</a>
            </li>
          </ul>
        </li>

        <li *appPermission="'READ_INDUSTRY_PRODUCTION_LINE'"
            routerLinkActive="active"
            [style.display]="headerVisible.industria ? 'block' : 'none'">
          <a routerLink="/resources/production-lines">
            <i class="ti-notepad"></i> <span>Lineas de producción</span>
          </a>
        </li>

        <li *appPermission="'READ_INDUSTRY_COMMAND'"
            routerLinkActive="active"
            [style.display]="headerVisible.industria ? 'block' : 'none'">
          <a routerLink="/industria/commands/productivity">
            <i class="ti-stats-up"></i> <span>Productividad</span>
          </a>
        </li>
      </ng-container>

      <!-- Almacén y depósitos -->
      <ng-container *appPermission="['READ_WAREHOUSE', 'READ_TANK']">
        <app-sidebar-header
          [expand]="headerVisible.wharehouseTanks"
          (click)="onToggleHeader('wharehouseTanks')">
          Almacén y depósitos
        </app-sidebar-header>

        <li *appPermission="'READ_WAREHOUSE'"
            routerLinkActive="active"
            [style.display]="headerVisible.wharehouseTanks ? 'block' : 'none'">
          <a routerLink="/resources/warehouses">
            <i class="fa fa-cubes"></i> <span>Almacenes</span>
          </a>
        </li>

        <li *appPermission="'READ_TANK'"
            routerLinkActive="active"
            [style.display]="headerVisible.wharehouseTanks ? 'block' : 'none'">
          <a routerLink="/resources/tanks">
            <i class="iconsmind-Pipe"></i> <span>Depósitos</span>
          </a>
        </li>
      </ng-container>

      <!-- Planificador -->
      <ng-container *appPermission="'READ_PLANNER'">
        <app-sidebar-header
          [expand]="headerVisible.planner"
          (click)="onToggleHeader('planner')">
          Planificador
        </app-sidebar-header>

        <li routerLinkActive="active" [style.display]="headerVisible.planner ? 'block' : 'none'">
          <a routerLink="/industria/planner/general">
            <i class="fa fa-bar-chart-o"></i> <span>Planificación de envasado</span>
          </a>
        </li>

        <li routerLinkActive="active" [style.display]="headerVisible.planner ? 'block' : 'none'">
          <a routerLink="/industria/planner/elaborated-products">
            <i class="fa fa-bar-chart-o"></i> <span>Planificación de productos elaborados</span>
          </a>
        </li>
      </ng-container>

      <!-- Producción (laboratorio) -->
      <ng-container *appPermission="'READ_PRODUCTION'">
        <app-sidebar-header
          [expand]="headerVisible.auditoria"
          (click)="onToggleHeader('auditoria')">
          Producción (laboratorio)
        </app-sidebar-header>

        <li routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}"
            [style.display]="headerVisible.auditoria ? 'block' : 'none'">
          <a routerLink="/production/registries">
            <i class="ti-files"></i> <span>Registros</span>
          </a>
        </li>

        <li *appRole="'ROLE_ADMIN'" routerLinkActive="active" [style.display]="headerVisible.auditoria ? 'block' : 'none'">
          <a routerLink="/production/registry-types">
            <i class="ti-notepad"></i> <span>Plantillas de registros</span>
          </a>
        </li>

        <li routerLinkActive="active" [style.display]="headerVisible.auditoria ? 'block' : 'none'">
          <a routerLink="/production/registries/analysis">
            <i class="ti-search"></i> <span>Análisis</span>
          </a>
        </li>

        <li routerLinkActive="active" [style.display]="headerVisible.auditoria ? 'block' : 'none'">
          <a routerLink="/production/registries/reception">
            <i class="ti-truck"></i> <span>Recepciones</span>
          </a>
        </li>

        <li routerLinkActive="active" [style.display]="headerVisible.auditoria ? 'block' : 'none'">
          <a routerLink="/production/registries/batch-mix">
            <i class="ti-exchange-vertical"></i> <span>Mezclas de lotes de materia prima</span>
          </a>
        </li>

        <li routerLinkActive="active" [style.display]="headerVisible.auditoria ? 'block' : 'none'">
          <a routerLink="/production/registries/mix-preparation">
            <i class="ti-dropbox-alt"></i> <span>Preparaciones de mezclas de MMPP</span>
          </a>
        </li>

        <li routerLinkActive="active" [style.display]="headerVisible.auditoria ? 'block' : 'none'">
          <a routerLink="/production/registries/load">
            <i class="ti-upload"></i> <span>Cargas - Descargas</span>
          </a>
        </li>

        <li routerLinkActive="active" [style.display]="headerVisible.auditoria ? 'block' : 'none'">
          <a routerLink="/production/registries/concentrate-fermentation">
            <i class="ti-server"></i> <span>Fermentación de concentrados</span>
          </a>
        </li>

        <li routerLinkActive="active" [style.display]="headerVisible.auditoria ? 'block' : 'none'">
          <a routerLink="/production/registries/clarification">
            <i class="ti-paint-roller"></i> <span>Clarificaciones</span>
          </a>
        </li>

        <li class="treeview" routerLinkActive="active" [style.display]="headerVisible.auditoria ? 'block' : 'none'">
          <a (click)="onToggleMenu($event, 'menu-open')">
            <i class="ti-filter"></i> <span>Filtraciones</span>
            <span class="float-right mr-2"><i class="fa fa-angle-right"></i></span>
          </a>
          <ul class="treeview-menu">
            <li routerLinkActive="active" [style.display]="headerVisible.auditoria ? 'block' : 'none'">
              <a routerLink="/production/registries/tangencial-filtration"><i class="ti-more"></i>Tangencial</a>
            </li>
            <li routerLinkActive="active" [style.display]="headerVisible.auditoria ? 'block' : 'none'">
              <a routerLink="/production/registries/plate-filtration"><i class="ti-more"></i>Placas</a>
            </li>
            <li routerLinkActive="active" [style.display]="headerVisible.auditoria ? 'block' : 'none'">
              <a routerLink="/production/registries/plate-change"><i class="ti-more"></i>Cambios de placas</a>
            </li>
            <li routerLinkActive="active" [style.display]="headerVisible.auditoria ? 'block' : 'none'">
              <a routerLink="/production/registries/sand-filtration"><i class="ti-more"></i>Tierras</a>
            </li>
          </ul>
        </li>

        <li routerLinkActive="active" [style.display]="headerVisible.auditoria ? 'block' : 'none'">
          <a routerLink="/production/registries/line-tracking">
            <i class="ti-share"></i> <span>Seguimiento de líneas</span>
          </a>
        </li>

        <li routerLinkActive="active" [style.display]="headerVisible.auditoria ? 'block' : 'none'">
          <a routerLink="/production/registries/line-preparation">
            <i class="ti-settings"></i> <span>Preparaciones de líneas</span>
          </a>
        </li>
      </ng-container>

      <!-- Lotes -->
      <ng-container *appPermission="'READ_BATCH'">
        <app-sidebar-header
          [expand]="headerVisible.batch"
          (click)="onToggleHeader('batch')">
          Lotes
        </app-sidebar-header>

        <li routerLinkActive="active" [style.display]="headerVisible.batch ? 'block' : 'none'">
          <a routerLink="/resources/batches">
            <i class="fa fa-barcode"></i> <span>Lotes</span>
          </a>
        </li>
      </ng-container>

      <!-- Productos y materiales -->
      <ng-container
        *appPermission="['READ_PRODUCT', 'READ_PRODUCT_FINISHED', 'READ_MATERIAL', 'READ_MATERIAL_AUXILIARY']">
        <app-sidebar-header
          [expand]="headerVisible.resources"
          (click)="onToggleHeader('resources')">
          Productos y materiales
        </app-sidebar-header>

        <li *appPermission="'READ_PRODUCT_FINISHED'"
            routerLinkActive="active"
            [style.display]="headerVisible.resources ? 'block' : 'none'">
          <a routerLink="/resources/products">
            <i class="fa fa-flask"></i> <span>Productos finalizados</span>
          </a>
        </li>

        <li *appPermission="'READ_PRODUCT_ELABORATED'"
            routerLinkActive="active"
            [style.display]="headerVisible.resources ? 'block' : 'none'">
          <a routerLink="/resources/elaborated-products">
            <i class="fa fa-gg"></i> <span>Productos elaborados</span>
          </a>
        </li>

        <li *appPermission="'READ_MATERIAL'"
            routerLinkActive="active"
            [style.display]="headerVisible.resources ? 'block' : 'none'">
          <a routerLink="/resources/raw-materials">
            <i class="fa fa-leaf"></i> <span>Materias primas</span>
          </a>
        </li>

        <li *appPermission="'READ_MATERIAL_AUXILIARY'"
            routerLinkActive="active"
            [style.display]="headerVisible.resources ? 'block' : 'none'">
          <a routerLink="/resources/materials">
            <i class="ti-shopping-cart-full"></i> <span>Materiales auxiliares</span>
          </a>
        </li>

        <li *appPermission="'READ_MATERIAL_ELABORATION'"
            routerLinkActive="active"
            [style.display]="headerVisible.resources ? 'block' : 'none'">
          <a routerLink="/resources/elaboration-materials">
            <i class="ti-paint-bucket"></i> <span>Materiales de elaboración</span>
          </a>
        </li>

        <li *appPermission="'READ_BRAND'"
            routerLinkActive="active"
            [style.display]="headerVisible.resources ? 'block' : 'none'">
          <a routerLink="/resources/brands">
            <i class="fa fa-lemon-o"></i> <span>Marcas</span>
          </a>
        </li>
      </ng-container>

      <!-- Empleados -->
      <ng-container *appPermission="'READ_EMPLOYEE'">
        <app-sidebar-header
          [expand]="headerVisible.employee"
          (click)="onToggleHeader('employee')">
          Empleados
        </app-sidebar-header>

        <li routerLinkActive="active" [style.display]="headerVisible.employee ? 'block' : 'none'">
          <a routerLink="/resources/employees">
            <i class="ti-user"></i> <span>Empleados</span>
          </a>
        </li>
      </ng-container>

      <!-- Logs -->
      <ng-container *appPermission="'READ_ERP'">
        <app-sidebar-header
          [expand]="headerVisible.logs"
          (click)="onToggleHeader('logs')">
          Logs
        </app-sidebar-header>

        <li routerLinkActive="active" [style.display]="headerVisible.logs ? 'block' : 'none'">
          <a routerLink="/resources/logs">
            <i class="ti-list"></i> <span>Logs</span>
          </a>
        </li>
      </ng-container>

    </ul>

  </section>

</aside>
