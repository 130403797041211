<!-- Content Header (Page header) -->
<div class="content-header">
  <div class="d-flex align-items-center">
    <div class="mr-auto">
      <div class="d-inline-block align-items-center breadcrumbs">
        <nav>
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><a routerLink="/"><i class="mdi mdi-home-outline"></i></a></li>

            <ng-container *ngIf="items">
              <li *ngFor="let item of items" aria-current="page" class="breadcrumb-item">
                <a *ngIf="item.link" [routerLink]="item.link" [queryParams]="item.queryParams">
                  {{ item.title }}
                </a>

                <ng-container *ngIf="!item.link">
                  {{ item.title }}
                </ng-container>
              </li>
            </ng-container>

            <li aria-current="page" class="breadcrumb-item active">{{ title }}</li>
          </ol>
        </nav>
      </div>
    </div>

  </div>
</div>
