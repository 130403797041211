import {ErrorHandler, Injectable, Injector} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {Router} from '@angular/router';
import {AuthenticationService} from '../services/authentication.service';
import {SnackBarService} from '../../../services/snack-bar.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  private authService: AuthenticationService;
  private router: Router;
  private notifier: SnackBarService;

  constructor(private injector: Injector) {
  }

  handleError(error: Error | HttpErrorResponse) {
    this.notifier = this.injector.get(SnackBarService);
    this.router = this.injector.get(Router);
    this.authService = this.injector.get(AuthenticationService);

    if (error instanceof HttpErrorResponse && error.status === 0) {
      // notifier.showError('Se ha perdido la conexión con el servidor');
    }

    if (error instanceof HttpErrorResponse && error.status === 401) {
      this.notifier.showError('Error de autentificación, por favor vuelva a iniciar sesión');
      this.authService.logout();
    }

    if (error instanceof HttpErrorResponse && error.status === 403) {
      this.notifier.showError('Error de autorización, no está autorizado para acceder al recurso');
      this.router.navigate(['/']);
    }

    console.error(error);
  }
}
