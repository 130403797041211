import { BehaviorSubject, of, throwError } from 'rxjs';
import { catchError, concatMap, map, switchMap, take, tap } from 'rxjs/operators';
import { Authentication } from '../models/authentication.model';
import jwtDecode from 'jwt-decode';
import * as i0 from "@angular/core";
import * as i1 from "./http/authentication-http.service";
import * as i2 from "../../../bundles/erp/bundles/employee/services/http/employee-http.service";
import * as i3 from "@angular/router";
export class AuthenticationService {
    constructor(authenticationHttpService, employeeHttpService, router) {
        this.authenticationHttpService = authenticationHttpService;
        this.employeeHttpService = employeeHttpService;
        this.router = router;
        this.authentication = new BehaviorSubject(null);
        this.currentEmployee = new BehaviorSubject(null);
    }
    static extractJwtTokenData(authentication) {
        const jwtObject = jwtDecode(authentication.access_id_token.replace('Bearer ', ''));
        authentication = Object.assign({}, authentication, {
            access_user_permissions: jwtObject.scopes,
            access_user_roles: jwtObject.roles
        });
        return authentication;
    }
    login(email, password) {
        return this.authenticationHttpService.login(email, password).pipe(tap((response) => {
            this.setAuthentication(response);
            localStorage.setItem('authentication', JSON.stringify(response));
        }), switchMap((response) => this.hasPermission(['READ_WEB'])), take(1), tap(hasPermission => {
            if (!hasPermission) {
                this.logout();
                throw new Error('El usuario no tiene permisos para acceder a la web');
            }
        }));
    }
    autoLogin() {
        const authentication = JSON.parse(localStorage.getItem('authentication'));
        if (this.isLoggedIn()) {
            this.setAuthentication(authentication);
        }
        else {
            this.logout();
        }
    }
    logout() {
        if (this.authentication.getValue()) {
            this.authenticationHttpService.logout(this.authentication.getValue().session_token).subscribe();
            this.authentication.next(null);
        }
        localStorage.removeItem('authentication');
        this.router.navigate(['login']);
    }
    isLoggedIn() {
        const authentication = JSON.parse(localStorage.getItem('authentication')) || new Authentication();
        return !!authentication.session_token || Date.now() < new Date(authentication.session_expiration).getTime();
    }
    createUser(user) {
        return this.authenticationHttpService.createUser(user);
    }
    lockUser(userId) {
        return this.authenticationHttpService.lockUser(userId);
    }
    unlockUser(userId) {
        return this.authenticationHttpService.unlockUser(userId);
    }
    updatePassword(userId, newPassword) {
        return this.authenticationHttpService.updatePassword(userId, newPassword);
    }
    queryUsers(params) {
        return this.authenticationHttpService.queryUsers(params);
    }
    assignUserRoles(userId, roleIds) {
        return this.authenticationHttpService.queryUsers({ id: userId }).pipe(map(page => page.content[0].roles), concatMap((currentRoleIds) => {
            let $observable = of(true);
            if (currentRoleIds.length) {
                $observable = this.authenticationHttpService.removeUserRoles(userId, currentRoleIds);
            }
            return $observable;
        }), concatMap(() => this.authenticationHttpService.addUserRoles(userId, roleIds)));
    }
    refreshAccess() {
        let authentication = JSON.parse(localStorage.getItem('authentication'));
        return this.authenticationHttpService.updateAccess(authentication).pipe(catchError((error) => {
            if (error.status === 401 || error.status === 400 || error.status === 500) {
                this.logout();
            }
            return throwError(error);
        })).pipe(tap(response => {
            authentication = Object.assign({}, authentication, {
                access_id_token: response.id_token,
                access_issued_at: response.issued_at,
                access_expiration: response.expiration,
            });
            this.setAuthentication(authentication);
            localStorage.setItem('authentication', JSON.stringify(authentication));
        }));
    }
    isAccessExpired() {
        const authentication = JSON.parse(localStorage.getItem('authentication')) || new Authentication();
        return !authentication.access_id_token || Date.now() > new Date(authentication.access_expiration).getTime();
    }
    queryRoles(params) {
        return this.authenticationHttpService.queryRoles(params);
    }
    hasPermission(permissions) {
        return this.authentication.pipe(map(authentication => {
            if (authentication && authentication.access_user_permissions) {
                for (const permission of permissions) {
                    for (const userPermission of authentication.access_user_permissions) {
                        if (permission.indexOf(userPermission) === 0) {
                            return true;
                        }
                    }
                }
            }
            return false;
        }));
    }
    hasRole(roles) {
        return this.authentication.pipe(map(authentication => {
            if (authentication && authentication.access_user_roles) {
                let hasRole = false;
                for (const role of roles) {
                    if (authentication.access_user_roles.indexOf(role) !== -1) {
                        hasRole = true;
                    }
                }
                return hasRole;
            }
        }));
    }
    setAuthentication(response) {
        response = AuthenticationService.extractJwtTokenData(response);
        this.authentication.next(response);
        this.employeeHttpService.findByAuthenticationUserId(response.session_user_id).subscribe(employee => this.currentEmployee.next(employee));
    }
}
AuthenticationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthenticationService_Factory() { return new AuthenticationService(i0.ɵɵinject(i1.AuthenticationHttpService), i0.ɵɵinject(i2.EmployeeHttpService), i0.ɵɵinject(i3.Router)); }, token: AuthenticationService, providedIn: "root" });
