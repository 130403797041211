import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../../environments/environment';
import {IAuthentication} from '../../models/authentication.model';
import {createRequestOption} from '../../../../helpers/request-util';
import {IUser} from '../../models/user.model';
import {INewPassword} from '../../models/newPassword.model';
import {IAccess} from '../../models/access.model';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationHttpService {
  public resourceUrl = environment.authApiUrl + 'api/v0';

  constructor(private httpClient: HttpClient) {
  }

  login(email: string, password: string): Observable<IAuthentication> {
    return this.httpClient.post<IAuthentication>(`${this.resourceUrl}/oauth2/login`, {email, password});
  }

  logout(sessionToken: string) {
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'}), body: {session_token: sessionToken}
    };
    return this.httpClient.delete(`${this.resourceUrl}/oauth2/logout`, httpOptions);
  }

  createUser(user: IUser): Observable<IUser> {
    return this.httpClient.post<IUser>(`${this.resourceUrl}/users`, user);
  }

  lockUser(userId: number): Observable<{}> {
    return this.httpClient.put<any>(`${this.resourceUrl}/users/${userId}/lock`, {});
  }

  unlockUser(userId: number): Observable<{}> {
    return this.httpClient.put<any>(`${this.resourceUrl}/users/${userId}/unlock`, {});
  }

  queryUsers(req?: any): Observable<any> {
    const options = createRequestOption(req);
    return this.httpClient.get<any>(`${this.resourceUrl}/users/search`, {params: options});
  }

  updateUserRoles(userId: number, roleIds: number[]): Observable<any> {
    return this.httpClient.put<any>(`${this.resourceUrl}/users/${userId}/roles`, {role_ids: roleIds});
  }

  addUserRoles(userId: number, roleIds: number[]): Observable<any> {
    return this.httpClient.post<any>(`${this.resourceUrl}/users/${userId}/roles`, {roleIds});
  }

  removeUserRoles(userId: number, roleIds: number[]): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'}), body: {roleIds}
    };
    return this.httpClient.delete<any>(`${this.resourceUrl}/users/${userId}/roles`, httpOptions);
  }

  queryRoles(req?: any): Observable<any> {
    const options = createRequestOption(req);
    return this.httpClient.get<any>(`${this.resourceUrl}/roles/search`, {params: options});
  }

  updatePassword(userId: number, newPassword: INewPassword): Observable<{}> {
    return this.httpClient.put<any>(`${this.resourceUrl}/users/${userId}/password`, newPassword);
  }

  updateAccess(authentication: IAuthentication): Observable<IAccess> {
    return this.httpClient.post<object>(`${this.resourceUrl}/oauth2/access`, {
      session_token: authentication.session_token,
      roles: authentication.access_user_roles,
      scopes: authentication.access_user_permissions
    });
  }
}
