<div class="modal">
  <div *ngIf="data.icon" class="modal-icon text-danger mb-20">
    <i [class]="data.icon"></i>
  </div>
  <div *ngIf="data.title" class="text-center" mat-dialog-title>
    {{ data.title }}
  </div>
  <div class="text-center" mat-dialog-content>
    <p>{{ data.content }}</p>
  </div>
  <div class="d-flex justify-content-center flex-wrap mt-10">
    <button (click)="closeModal(button.value)" *ngFor="let button of data.buttons"
            [ngClass]="{'three-buttons': data.buttons.length === 3}"
            [class]="'mx-10 btn btn-' + button.color"><span>{{ button.label }}</span></button>
  </div>
</div>
