<!-- Main content -->
<section class="content">
  <div class="row">
    <div class="col-12 col-header-login">
      <div class="box custom-box-login">
        <div class="box-header-login">
          <h4 class="box-title-login">Inicio de sesión</h4>
        </div>

        <div class="col-12">
          <!-- Logo -->
          <a class="logo-header" routerLink="/">
            <img alt="Ecovinal" src="../../../assets/img/logo.png">
          </a>
        </div>

        <app-login-form *ngIf="!isLoggedIn()" class="login-form"></app-login-form>
      </div>
    </div>
    <!-- /.col -->
  </div>
  <!-- /.row -->
</section>
<!-- /.content -->
