import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from '../../middlewares/authentication/services/authentication.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './login-layout.component.html',
  styleUrls: ['./login-layout.component.scss']
})
export class LoginLayoutComponent implements OnInit {
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
  ) {
  }

  ngOnInit() {
    this.authenticationService.autoLogin();
    if (this.isLoggedIn()) {
      this.router.navigateByUrl('/');
    }
  }

  isLoggedIn(): boolean {
    return this.authenticationService.isLoggedIn();
  }
}
