<div class="logout-floating-box card">
  <div class="card-body mt-4">
    <p>
      <span *ngIf="employee" class="color-secondary">Hola, {{employee.firstName}} {{employee.lastName}}</span>
      <mat-icon (click)="onLogoutClickButton()" class="color-primary" matTooltip="Cerrar sesión" role="button">
        power_settings_new
      </mat-icon>
    </p>
  </div>
</div>
