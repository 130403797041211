// tslint:disable:variable-name

export interface IAuthentication {
  session_token?: string;
  session_issued_at?: string;
  session_expiration?: string;
  session_user_id?: number;
  access_id_token?: string;
  access_issued_at?: string;
  access_expiration?: string;
  access_user_id?: number;
  access_user_roles?: any;
  access_user_permissions?: any;
}

export class Authentication implements IAuthentication {
  constructor(
    public session_token?: string,
    public session_issued_at?: string,
    public session_expiration?: string,
    public session_user_id?: number,
    public access_id_token?: string,
    public access_issued_at?: string,
    public access_expiration?: string,
    public access_user_id?: number,
    public access_user_roles?: any,
    public access_user_permissions?: any,
  ) {
    this.access_issued_at = new Date().toDateString();
    this.access_expiration = new Date().toDateString();
    this.session_issued_at = new Date().toDateString();
    this.session_expiration = new Date().toDateString();
  }
}
