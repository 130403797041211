import { map, take } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "../services/authentication.service";
import * as i2 from "@angular/router";
export class AuthenticatedGuard {
    constructor(authService, router) {
        this.authService = authService;
        this.router = router;
    }
    canActivate(route, state) {
        return this.authService.authentication.pipe(take(1), map(authentication => {
            // If there is no session opened or the session token has expired redirect to login
            if (!authentication || !authentication.access_id_token) {
                this.authService.logout();
                return this.router.createUrlTree(['/login']);
            }
            return true;
        }));
    }
}
AuthenticatedGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthenticatedGuard_Factory() { return new AuthenticatedGuard(i0.ɵɵinject(i1.AuthenticationService), i0.ɵɵinject(i2.Router)); }, token: AuthenticatedGuard, providedIn: "root" });
