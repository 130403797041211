import { Routes } from '@angular/router';
import { AuthenticatedGuard } from './middlewares/authentication/guards/authenticated.guard';
import { AppLayoutComponent } from './layouts/app-layout/app-layout.component';
import { LoginLayoutComponent } from './layouts/login-layout/login-layout.component';
import { DashboardPageComponent } from './layouts/app-layout/components/pages/dashboard-page/dashboard-page.component';
import { AuthorizationGuard } from 'src/app/middlewares/authentication/guards/authorization.guard';
const ɵ0 = { permission: ['READ_PRODUCT'] }, ɵ1 = () => import("./bundles/auditoria/auditoria.module.ngfactory").then((m) => m.AuditoriaModuleNgFactory), ɵ2 = () => import("./bundles/industria/industria.module.ngfactory").then(m => m.IndustriaModuleNgFactory), ɵ3 = () => import("./bundles/erp/erp.module.ngfactory").then(m => m.ErpModuleNgFactory), ɵ4 = { permission: ['READ_SILICIE'] }, ɵ5 = () => import("./bundles/silicie/silicie.module.ngfactory").then(m => m.SilicieModuleNgFactory), ɵ6 = { permission: ['READ_BI'] }, ɵ7 = () => import("./bundles/bi/bi.module.ngfactory").then(m => m.BiModuleNgFactory);
const routes = [
    {
        path: '',
        canActivate: [AuthenticatedGuard],
        component: AppLayoutComponent,
        children: [
            {
                path: '',
                component: DashboardPageComponent,
            },
            {
                path: 'production',
                data: ɵ0,
                canLoad: [AuthorizationGuard],
                loadChildren: ɵ1,
            },
            {
                path: 'industria',
                loadChildren: ɵ2
            },
            {
                path: 'resources',
                loadChildren: ɵ3
            },
            {
                path: 'silicie',
                data: ɵ4,
                canLoad: [AuthorizationGuard],
                loadChildren: ɵ5
            },
            {
                path: 'bi',
                data: ɵ6,
                canLoad: [AuthorizationGuard],
                loadChildren: ɵ7
            }
        ]
    },
    {
        path: 'login',
        component: LoginLayoutComponent,
    },
    {
        path: '**',
        redirectTo: '/'
    }
];
export class AppRoutingModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7 };
