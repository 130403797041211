<div class="row">
  <div class="col-12">
    <div class="box">
      <div *ngIf="title" class="box-header">
        <h4 class="box-title">{{title}}</h4>
      </div>
      <div class="box-body">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
