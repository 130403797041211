<app-page-header title="Panel de control"></app-page-header>

<div class="container-fluid">
  <div class="row">
    <div class="col-6">
      <!-- ERP-->
      <div *appPermission="'READ_ERP'">
        <app-card-wrapper title="ERP">
          <dl>
            <dt>
              <i class="fa fa-file-text-o"></i> <span class="ml-2">Compras</span>
            </dt>
            <dd>
              Gestión de compras y proveedores.
              <p>
                <a routerLink="/resources/purchase-orders">Pedidos de compra</a>,
                <a routerLink="/resources/delivery-notes">Albaranes</a>,
                <a routerLink="/resources/purchase-invoices">Facturas de compra</a>,
                <a routerLink="/resources/suppliers">Proveedores</a>,
                <a routerLink="/resources/logistic-suppliers">Proveedores logísticos</a>,
                <a routerLink="/resources/logistic-services">Servicios logísticos</a>
              </p>
            </dd>

            <dt>
              <i class="fa fa-file-text"></i> <span class="ml-2">Ventas</span>
            </dt>
            <dd>
              Gestión de ventas y clientes.
              <p>
                <a routerLink="/resources/sales-orders">Pedidos de venta</a>,
                <a routerLink="/resources/packing-lists">Packing list</a>,
                <a routerLink="/resources/sales-invoices">Facturas de venta</a>,
                <!--                <a routerLink="/resources/sales-invoice-rectifications">Rectificaciones de venta</a>,-->
                <a routerLink="/resources/sales-invoice-amends">Abonos de venta</a>,
                <a routerLink="/resources/clients">Clientes</a>
              </p>
            </dd>

            <dt>
              <i class="ti-settings"></i> <span class="ml-2">Parámetros</span>
            </dt>
            <dd>
              Gestión de los parámetros de proveedores y clientes.
              <p>
                <a routerLink="/resources/parameters/incoterms">Incoterms</a>,
                <a routerLink="/resources/parameters/payment-methods">Métodos de pago</a>,
                <a routerLink="/resources/parameters/tax-groups">Grupos contables</a>,
                <a routerLink="/resources/parameters/transport-modes">Modos de transporte</a>,
                <a routerLink="/resources/parameters/warehouse-codes">Códigos de almacén</a>,
                <a routerLink="/resources/parameters/documental-requirements">Requisitos documentales</a>
              </p>
            </dd>

            <dt>
              <a routerLink="/resources/warehouses-report">
                <i class="fa fa-cubes"></i> <span class="ml-2">Valoración económica del stock</span>
              </a>
            </dt>
            <dd>
              Valoración financiera del stock de material y producto.
            </dd>
          </dl>
        </app-card-wrapper>
      </div>

      <!-- Industria -->
      <div
        *appPermission="['READ_INDUSTRY', 'READ_INDUSTRY_CONTROL', 'READ_INDUSTRY_INCIDENT', 'READ_INDUSTRY_PLANNER_VISUALIZATION']">
        <app-card-wrapper title="Industria">
          <dl>
            <ng-container *appPermission="'READ_INDUSTRY_PLANNER_VISUALIZATION'">
              <dt>
                <a routerLink="/industria/planner/view">
                  <i class="fa fa-bar-chart-o"></i> <span class="ml-2">Visualizar planificación</span>
                </a>
              </dt>
              <dd>
                Visualización de las ordenes de envasado y a granel planificadas.
              </dd>
            </ng-container>
            <ng-container *appPermission="'READ_INDUSTRY_COMMAND'">
              <dt>
                <a routerLink="/industria/commands">
                  <i class="ti-notepad"></i> <span class="ml-2">Ordenes de producción</span>
                </a>
              </dt>
              <dd>
                Gestión de las órdenes de envasado y a granel.
              </dd>
            </ng-container>
            <ng-container *appPermission="'READ_INDUSTRY_CONTROL'">
              <dt>
                <a routerLink="/industria/controls">
                  <i class="ti-agenda"></i> <span class="ml-2">Registro de controles</span>
                </a>
              </dt>
              <dd>
                Gestión del histórico de los controles de industria cumplimentados.
              </dd>
            </ng-container>
            <ng-container *appPermission="'READ_INDUSTRY_CONTROL_TEMPLATE'">
              <dt>
                <a routerLink="/industria/control-types">
                  <i class="ti-stamp"></i> <span class="ml-2">Plantillas de control</span>
                </a>
              </dt>
              <dd>
                Gestión de las plantillas de los controles de industria.
              </dd>
            </ng-container>
            <ng-container *appPermission="'READ_INDUSTRY_INCIDENT'">
              <dt>
                <a routerLink="/industria/incidences">
                  <i class="fa fa-exclamation-triangle"></i> <span class="ml-2">Incidencias</span>
                </a>
              </dt>
              <dd>
                Gestión de las incidencias cumplimentadas y plantillas.
              </dd>
            </ng-container>
            <ng-container *appPermission="'READ_INDUSTRY_SENSOR'">
              <dt>
                <i class="ti-panel"></i> <span class="ml-2">Sensórica depositos</span>
              </dt>
              <dd>
                Gestión de los sensores de medición de nivel de los depósitos.
                <p>
                  <a routerLink="/resources/tank-sensor-groups">Concentradores de datos</a>,
                  <a routerLink="/resources/tank-sensors">Sensores de nivel</a>
                </p>
              </dd>
            </ng-container>
            <ng-container *appPermission="'READ_INDUSTRY_PRODUCTION_LINE'">
              <dt>
                <a routerLink="/resources/production-lines">
                  <i class="ti-notepad"></i> <span class="ml-2">Lineas de producción</span>
                </a>
              </dt>
              <dd>
                Gestión de los puestos de las líneas de envasado.
              </dd>
            </ng-container>
          </dl>
        </app-card-wrapper>
      </div>

      <!-- Producción (laboratorio) -->
      <div *appPermission="'READ_PRODUCTION'">
        <app-card-wrapper title="Producción (laboratorio)">
          <dl>
            <dt>
              <a routerLink="/production/registries">
                <i class="ti-files"></i> <span class="ml-2">Registros</span>
              </a>
            </dt>
            <dd>
              Gestión de los registros cumplimentados de producción.
            </dd>

            <dt *appRole="'ROLE_ADMIN'">
              <a routerLink="/production/registry-types">
                <i class="ti-notepad"></i> <span class="ml-2">Plantillas de registros</span>
              </a>
            </dt>
            <dd *appRole="'ROLE_ADMIN'">
              Gestión de las plantillas de los registros de producción.
            </dd>

            <dt>
              <a routerLink="/production/registries/analysis">
                <i class="ti-search"></i> <span class="ml-2">Análisis</span>
              </a>
            </dt>
            <dd>
              Registros de los análisis de laboratorio.
            </dd>

            <dt>
              <a routerLink="/production/registries/reception">
                <i class="ti-truck"></i> <span class="ml-2">Recepciones</span>
              </a>
            </dt>
            <dd>
              Registros de las recepciones de materia prima.
            </dd>

            <dt>
              <a routerLink="/production/registries/batch-mix">
                <i class="ti-exchange-vertical"></i> <span class="ml-2">Mezclas de lotes de materia prima</span>
              </a>
            </dt>
            <dd>
              Registros de las mezclas de materias primas.
            </dd>

            <dt>
              <a routerLink="/production/registries/mix-preparation">
                <i class="ti-dropbox-alt"></i> <span class="ml-2">Preparaciones de mezclas de MMPP</span>
              </a>
            </dt>
            <dd>
              Registros de las preparaciones de las mezclas de lotes de materia prima.
            </dd>

            <dt>
              <a routerLink="/production/registries/load">
                <i class="ti-upload"></i> <span class="ml-2">Cargas - Descargas</span>
              </a>
            </dt>
            <dd>
              Registros de las cargas y descargas en los fermentadores.
            </dd>

            <dt>
              <a routerLink="/production/registries/concentrate-fermentation">
                <i class="ti-server"></i> <span class="ml-2">Fermentación de concentrados</span>
              </a>
            </dt>
            <dd>
              Registros de las cargas y descargas en los fermentadores.
            </dd>

            <dt>
              <a routerLink="/production/registries/clarification">
                <i class="ti-paint-roller"></i> <span class="ml-2">Clarificaciones</span>
              </a>
            </dt>
            <dd>
              Registros del proceso de clarificación.
            </dd>

            <dt>
              <i class="ti-filter"></i> <span class="ml-2">Filtraciones</span>
            </dt>
            <dd>
              Registros de los procesos de filtración.
              <p>
                <a routerLink="/production/registries/tangencial-filtration">Tangencial</a>,
                <a routerLink="/production/registries/plate-filtration">Placas</a>,
                <a routerLink="/production/registries/plate-change">Cambios de placas</a>,
                <a routerLink="/production/registries/sand-filtration">Tierras</a>
              </p>
            </dd>

            <dt>
              <a routerLink="/production/registries/line-tracking">
                <i class="ti-share"></i> <span class="ml-2">Seguimiento de líneas</span>
              </a>
            </dt>
            <dd>
              Registros del proceso de seguimiento de líneas.
            </dd>

            <dt>
              <a routerLink="/production/registries/line-preparation">
                <i class="ti-settings"></i> <span class="ml-2">Preparaciones de líneas</span>
              </a>
            </dt>
            <dd>
              Registros de las preparaciones de líneas.
            </dd>
          </dl>
        </app-card-wrapper>
      </div>
    </div>

    <div class="col-6">
      <!-- BI -->
      <div *appPermission="'READ_BI'">
        <app-card-wrapper title="BI">
          <dl>
            <dt>
              <a routerLink="/bi/dashboard">
                <i class="ti-dashboard"></i> <span class="ml-2">BI Dashboard</span>
              </a>
            </dt>
            <dd>
              Dashboard de Business Intelligence.
            </dd>
          </dl>
        </app-card-wrapper>
      </div>

      <!-- Silicie -->
      <div *appPermission="'READ_SILICIE'">
        <app-card-wrapper title="SILICIE">
          <dl>
            <dt>
              <a routerLink="/silicie/seat">
                <i class="ti-layout-list-thumb-alt"></i> <span class="ml-2">Asientos</span>
              </a>
            </dt>
            <dd>
              Contabilidad de productos bajo Impuestos Especiales.
            </dd>
            <dt>
              <a routerLink="/silicie/iesa">
                <i class="ti-layout-accordion-merged"></i> <span class="ml-2">IESA</span>
              </a>
            </dt>
            <dd>
              Registros de agrupación de asientos.
            </dd>
          </dl>
        </app-card-wrapper>
      </div>

      <!-- Planificador -->
      <div *appPermission="'READ_PLANNER'">
        <app-card-wrapper title="Planificador">
          <dl>
            <dt>
              <a routerLink="/industria/planner/general">
                <i class="fa fa-bar-chart-o"></i> <span class="ml-2">Planificador</span>
              </a>
            </dt>
            <dd>
              Gestión de la planificación de la producción.
            </dd>
          </dl>
        </app-card-wrapper>
      </div>

      <!-- Almacén y depósitos -->
      <div *appPermission="['READ_WAREHOUSE', 'READ_TANK']">
        <app-card-wrapper title="Almacén y depósitos">
          <dl>
            <ng-container *appPermission="'READ_WAREHOUSE'">
              <dt>
                <a routerLink="/resources/warehouses">
                  <i class="fa fa-cubes"></i> <span class="ml-2">Almacenes</span>
                </a>
              </dt>
              <dd>
                Gestión de los almacenes y consulta del stock.
              </dd>
            </ng-container>
            <ng-container *appPermission="'READ_TANK'">
              <dt>
                <a routerLink="/resources/tanks">
                  <i class="iconsmind-Pipe"></i> <span class="ml-2">Depósitos</span>
                </a>
              </dt>
              <dd>
                Gestión de los depósitos y nivel de líquidos.
              </dd>
            </ng-container>
          </dl>
        </app-card-wrapper>
      </div>

      <!-- Lotes -->
      <div *appPermission="'READ_BATCH'">
        <app-card-wrapper title="Lotes">
          <dl>
            <dt>
              <a routerLink="/resources/batches">
                <i class="fa fa-barcode"></i> <span class="ml-2">Lotes</span>
              </a>
            </dt>
            <dd>
              Gestión de los lotes de los diferentes productos y materiales.
            </dd>
          </dl>
        </app-card-wrapper>
      </div>

      <!-- Productos y materiales -->
      <div *appPermission="['READ_PRODUCT', 'READ_PRODUCT_FINISHED', 'READ_MATERIAL', 'READ_MATERIAL_AUXILIARY']">
        <app-card-wrapper title="Productos y materiales">
          <ng-container *appPermission="'READ_PRODUCT_FINISHED'">
            <dt>
              <a routerLink="/resources/products">
                <i class="fa fa-flask"></i> <span class="ml-2">Productos finalizados</span>
              </a>
            </dt>
            <dd>
              Gestión de las especificaciones de los productos finalizados.
            </dd>
          </ng-container>

          <ng-container *appPermission="'READ_PRODUCT_ELABORATED'">
            <dt>
              <a routerLink="/resources/elaborated-products">
                <i class="fa fa-gg"></i> <span class="ml-2">Productos elaborados</span>
              </a>
            </dt>
            <dd>
              Gestión de las especificaciones de los productos elaborados.
            </dd>
          </ng-container>

          <ng-container *appPermission="'READ_MATERIAL'">
            <dt>
              <a routerLink="/resources/raw-materials">
                <i class="fa fa-leaf"></i> <span class="ml-2">Materias primas</span>
              </a>
            </dt>
            <dd>
              Gestión de las especificaciones de las materias primas.
            </dd>
          </ng-container>

          <ng-container *appPermission="'READ_MATERIAL_AUXILIARY'">
            <dt>
              <a routerLink="/resources/materials">
                <i class="ti-shopping-cart-full"></i> <span class="ml-2">Materiales auxiliares</span>
              </a>
            </dt>
            <dd>
              Gestión de las especificaciones del material auxiliar.
            </dd>
          </ng-container>

          <ng-container *appPermission="'READ_MATERIAL_ELABORATION'">
            <dt>
              <a routerLink="/resources/elaboration-materials">
                <i class="ti-paint-bucket"></i> <span class="ml-2">Materiales de elaboración</span>
              </a>
            </dt>
            <dd>
              Gestión de las especificaciones de los productos elaborados.
            </dd>
          </ng-container>

          <ng-container *appPermission="'READ_BRAND'">
            <dt>
              <a routerLink="/resources/brands">
                <i class="fa fa-lemon-o"></i> <span class="ml-2">Marcas</span>
              </a>
            </dt>
            <dd>
              Gestión de las marcas.
            </dd>
          </ng-container>
        </app-card-wrapper>
      </div>

      <!-- Empleados -->
      <div *appPermission="'READ_EMPLOYEE'">
        <app-card-wrapper title="Empleados">
          <dt>
            <a routerLink="/resources/employees">
              <i class="ti-user"></i> <span class="ml-2">Empleados</span>
            </a>
          </dt>
          <dd>
            Gestión de los perfiles de los empleados, usuarios de acceso y roles de la plataforma.
          </dd>
        </app-card-wrapper>
      </div>

      <div *appPermission="'READ_ERP'">
        <app-card-wrapper title="Logs">
          <dt>
            <a routerLink="/resources/logs">
              <i class="ti-list"></i> <span class="ml-2">Logs</span>
            </a>
          </dt>
          <dd>
            Registro de los eventos más importantes ocurridos en el servidor.
          </dd>
        </app-card-wrapper>
      </div>

    </div>
  </div>
</div>