import {Component, OnInit, Renderer2} from '@angular/core';
import {Observable} from 'rxjs';
import {AuthenticationService} from '../../../../../middlewares/authentication/services/authentication.service';
import {IAuthentication} from '../../../../../middlewares/authentication/models/authentication.model';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  authentication: Observable<IAuthentication>;
  open = false;
  headerVisible = {
    silicie: true,
    industria: true,
    auditoria: true,
    resources: true,
    erp: true,
    batch: true,
    wharehouseTanks: true,
    planner: true,
    employee: true,
    logs: true,
    bi: true,
  };

  constructor(
    private renderer: Renderer2,
    private authenticationService: AuthenticationService
  ) {
  }

  ngOnInit() {
  }

  onToggleMenu(evt: any, className: string) {
    const items = Array.from(document.body.getElementsByClassName('treeview'));
    const hasClass = evt.currentTarget.parentElement.classList.contains(className);

    items.map(el => {
      this.renderer.removeClass(el, 'menu-open');
    });

    if (hasClass) {
      this.renderer.removeClass(evt.currentTarget.parentElement, className);
    } else {
      this.renderer.addClass(evt.currentTarget.parentElement, className);
    }
  }

  isLoggedIn(): boolean {
    return this.authenticationService.isLoggedIn();
  }

  onToggleHeader(header: string) {
    this.headerVisible[header] = !this.headerVisible[header];
  }

}
