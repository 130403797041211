<form (ngSubmit)="onSubmit()" [formGroup]="loginForm">
  <div class="box-body">
    <div class="row">

      <div class="col-md-4 col-12"></div>

      <ng-container *ngIf="loading">
        <div class="col-md-4 col-12">
          <mat-spinner></mat-spinner>
        </div>
      </ng-container>

      <div *ngIf="!loading" class="col-md-4 col-12">

        <ng-container *ngIf="error">
          <div *ngIf="error" class="mb-4 alert alert-danger" role="alert">
            <strong><i class="fa fa-warning"></i></strong>
            Usuario y/o contraseña incorrectos
            <strong (click)="onCloseErrorClick()" class="pull-right" role="button"><i class="fa fa-times"></i></strong>
          </div>
        </ng-container>

        <!-- User Field -->
        <mat-form-field class="w100">
          <mat-label>Usuario</mat-label>
          <input formControlName="user" matInput type="email"/>
        </mat-form-field>

        <!-- Password Field -->
        <mat-form-field class="w100">
          <mat-label>Contraseña</mat-label>
          <input formControlName="password" matInput type="password"/>
        </mat-form-field>

        <!-- Submit Button -->
        <button [disabled]="!loginForm.valid" class="btn btn-primary my-20 w100" matTooltip="Iniciar sesión"
                type="submit">
          <mat-icon>login</mat-icon>
          <span class="ml-2">Entrar</span>
        </button>

      </div>

      <div class="col-md-4 col-12"></div>

    </div>

  </div>

</form>
