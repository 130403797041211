import { NgZone } from '@angular/core';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/snack-bar";
export class SnackBarService {
    constructor(snackBar, zone) {
        this.snackBar = snackBar;
        this.zone = zone;
    }
    showSuccess(message) {
        this.zone.run(() => {
            this.snackBar.open(message, 'Cerrar', { duration: 4000 });
        });
    }
    showError(message) {
        this.zone.run(() => {
            this.snackBar.open(message, 'Cerrar', { panelClass: ['snack-error'], duration: 4000 });
        });
    }
}
SnackBarService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SnackBarService_Factory() { return new SnackBarService(i0.ɵɵinject(i1.MatSnackBar), i0.ɵɵinject(i0.NgZone)); }, token: SnackBarService, providedIn: "root" });
