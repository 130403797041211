import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {Injectable} from '@angular/core';
import {catchError, concatMap, exhaustMap, map, take} from 'rxjs/operators';
import {AuthenticationService} from '../services/authentication.service';
import {Router} from '@angular/router';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {

  constructor(private authenticationService: AuthenticationService,
              private router: Router) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.authenticationService.authentication.pipe(
      take(1),
      concatMap((authentication) => {
        if (this.authenticationService.isLoggedIn() && this.authenticationService.isAccessExpired()) {
          if (req.url.indexOf('/oauth2/access') !== -1) {
            return of(authentication);
          }

          return this.authenticationService.refreshAccess().pipe(
            map((access) => {
              return {...authentication, ...{access_id_token: access.id_token}};
            }),
          );
        }

        return of(authentication);
      }),
      exhaustMap(authentication => {
        let clonedRequest = req;
        if (authentication) {
          clonedRequest = req.clone({
            headers: req.headers.set('Authorization', authentication.access_id_token)
          });
        }
        return next.handle(clonedRequest);
      })
    );
  }
}
