<div class="page-wrapper wrapper">
  <!-- Header -->
  <app-header></app-header>

  <!-- Sidebar -->
  <app-sidebar></app-sidebar>

  <!-- Logout -->
  <app-logout></app-logout>

  <!-- Content Wrapper. Contains page content -->
  <div class="content-wrapper">
    <div class="container-full">
      <!-- Main content -->
      <section class="content">
        <div class="row">
          <div class="col-12">
            <router-outlet></router-outlet>
          </div>
        </div>
      </section>
      <!-- /.content -->
    </div>
  </div>
</div>
