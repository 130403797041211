import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../../../../environments/environment';
import {IEmployee} from '../../models/employee.model';
import {createRequestOption} from '../../../../../../helpers/request-util';
import {Page} from '../../../../../../models/Page';

@Injectable({providedIn: 'root'})
export class EmployeeHttpService {
  public resourceUrl = environment.erpApiUrl + 'api/employees';

  constructor(protected http: HttpClient) {
  }

  create(employee: IEmployee): Observable<IEmployee> {
    return this.http.post<IEmployee>(this.resourceUrl, employee);
  }

  update(employee: IEmployee): Observable<IEmployee> {
    return this.http.put<IEmployee>(this.resourceUrl, employee);
  }

  find(id: number): Observable<IEmployee> {
    return this.http.get<IEmployee>(`${this.resourceUrl}/${id}`);
  }

  findByAuthenticationUserId(id: number): Observable<IEmployee> {
    return this.http.get<IEmployee>(`${this.resourceUrl}/${id}?auth=true`);
  }

  query(req?: any): Observable<Page<IEmployee>> {
    const options = createRequestOption(req);
    return this.http.get<Page<IEmployee>>(this.resourceUrl, {params: options});
  }

  delete(id: number): Observable<{}> {
    return this.http.delete(`${this.resourceUrl}/${id}`);
  }

  nextStamp(): Observable<number> {
    return this.http.get<number>(`${this.resourceUrl}/next-stamp`);
  }
}
