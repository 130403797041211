import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {AuthenticationService} from '../../../../../middlewares/authentication/services/authentication.service';
import {SnackBarService} from '../../../../../services/snack-bar.service';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements OnInit {

  loginForm: FormGroup;
  error = false;
  loading = true;

  constructor(
    private fb: FormBuilder,
    private authenticationService: AuthenticationService,
    private router: Router,
    private snackBar: SnackBarService
  ) {
  }

  ngOnInit() {
    this.initForm();
    this.loading = false;
  }

  onSubmit() {
    this.submitForm();
  }

  onCloseErrorClick() {
    this.closeError();
  }

  initForm() {
    this.loginForm = this.fb.group({
      user: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]]
    });
  }

  async submitForm() {
    const email = this.loginForm.get('user').value;
    const password = this.loginForm.get('password').value;
    this.authenticationService.login(email, password)
      .subscribe(
        _ => this.router.navigateByUrl('/'),
        _ => this.snackBar.showError('Correo electrónico o contraseña invalidos.'));
  }

  closeError(): void {
    this.error = false;
  }

}
