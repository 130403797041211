/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./login-layout.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./components/utils/login-form/login-form.component.ngfactory";
import * as i3 from "./components/utils/login-form/login-form.component";
import * as i4 from "@angular/forms";
import * as i5 from "../../middlewares/authentication/services/authentication.service";
import * as i6 from "@angular/router";
import * as i7 from "../../services/snack-bar.service";
import * as i8 from "@angular/common";
import * as i9 from "./login-layout.component";
var styles_LoginLayoutComponent = [i0.styles];
var RenderType_LoginLayoutComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoginLayoutComponent, data: {} });
export { RenderType_LoginLayoutComponent as RenderType_LoginLayoutComponent };
function View_LoginLayoutComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-login-form", [["class", "login-form"]], null, null, null, i2.View_LoginFormComponent_0, i2.RenderType_LoginFormComponent)), i1.ɵdid(1, 114688, null, 0, i3.LoginFormComponent, [i4.FormBuilder, i5.AuthenticationService, i6.Router, i7.SnackBarService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_LoginLayoutComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "section", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 11, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 10, "div", [["class", "col-12 col-header-login"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 9, "div", [["class", "box custom-box-login"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "box-header-login"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "h4", [["class", "box-title-login"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Inicio de sesi\u00F3n"])), (_l()(), i1.ɵeld(7, 0, null, null, 3, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "a", [["class", "logo-header"], ["routerLink", "/"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(9, 671744, null, 0, i6.RouterLinkWithHref, [i6.Router, i6.ActivatedRoute, i8.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 0, "img", [["alt", "Ecovinal"], ["src", "../../../assets/img/logo.png"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LoginLayoutComponent_1)), i1.ɵdid(12, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = "/"; _ck(_v, 9, 0, currVal_2); var currVal_3 = !_co.isLoggedIn(); _ck(_v, 12, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 9).target; var currVal_1 = i1.ɵnov(_v, 9).href; _ck(_v, 8, 0, currVal_0, currVal_1); }); }
export function View_LoginLayoutComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dashboard", [], null, null, null, View_LoginLayoutComponent_0, RenderType_LoginLayoutComponent)), i1.ɵdid(1, 114688, null, 0, i9.LoginLayoutComponent, [i5.AuthenticationService, i6.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoginLayoutComponentNgFactory = i1.ɵccf("app-dashboard", i9.LoginLayoutComponent, View_LoginLayoutComponent_Host_0, {}, {}, []);
export { LoginLayoutComponentNgFactory as LoginLayoutComponentNgFactory };
