import { environment } from '../../../../../../../environments/environment';
import { createRequestOption } from '../../../../../../helpers/request-util';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class EmployeeHttpService {
    constructor(http) {
        this.http = http;
        this.resourceUrl = environment.erpApiUrl + 'api/employees';
    }
    create(employee) {
        return this.http.post(this.resourceUrl, employee);
    }
    update(employee) {
        return this.http.put(this.resourceUrl, employee);
    }
    find(id) {
        return this.http.get(`${this.resourceUrl}/${id}`);
    }
    findByAuthenticationUserId(id) {
        return this.http.get(`${this.resourceUrl}/${id}?auth=true`);
    }
    query(req) {
        const options = createRequestOption(req);
        return this.http.get(this.resourceUrl, { params: options });
    }
    delete(id) {
        return this.http.delete(`${this.resourceUrl}/${id}`);
    }
    nextStamp() {
        return this.http.get(`${this.resourceUrl}/next-stamp`);
    }
}
EmployeeHttpService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EmployeeHttpService_Factory() { return new EmployeeHttpService(i0.ɵɵinject(i1.HttpClient)); }, token: EmployeeHttpService, providedIn: "root" });
