import {Directive, Input, OnDestroy, TemplateRef, ViewContainerRef} from '@angular/core';
import {AuthenticationService} from '../services/authentication.service';
import {Subscription} from 'rxjs';

@Directive({
  selector: '[appPermission]'
})
export class PermissionDirective implements OnDestroy {

  subscription: Subscription;

  constructor(private authService: AuthenticationService,
              private templateRef: TemplateRef<any>,
              private vcRef: ViewContainerRef) {
  }

  @Input('appPermission') set hasPermission(permission: string | string[]) {
    const argument = Array.isArray(permission) ? permission : [permission];
    this.subscription = this.authService.hasPermission(argument)
      .subscribe(authorized => {
        if (authorized) {
          this.vcRef.clear();
          this.vcRef.createEmbeddedView(this.templateRef);
        } else {
          this.vcRef.clear();
        }
      });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
