/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app-layout.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./components/utils/header/header.component.ngfactory";
import * as i3 from "./components/utils/header/header.component";
import * as i4 from "./components/utils/sidebar/sidebar.component.ngfactory";
import * as i5 from "./components/utils/sidebar/sidebar.component";
import * as i6 from "../../middlewares/authentication/services/authentication.service";
import * as i7 from "./components/utils/logout-form/logout-form.component.ngfactory";
import * as i8 from "./components/utils/logout-form/logout-form.component";
import * as i9 from "../../bundles/erp/bundles/employee/services/http/employee-http.service";
import * as i10 from "@angular/router";
import * as i11 from "./app-layout.component";
var styles_AppLayoutComponent = [i0.styles];
var RenderType_AppLayoutComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppLayoutComponent, data: {} });
export { RenderType_AppLayoutComponent as RenderType_AppLayoutComponent };
export function View_AppLayoutComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "page-wrapper wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-header", [], null, null, null, i2.View_HeaderComponent_0, i2.RenderType_HeaderComponent)), i1.ɵdid(2, 114688, null, 0, i3.HeaderComponent, [i1.Renderer2], null, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-sidebar", [], null, null, null, i4.View_SidebarComponent_0, i4.RenderType_SidebarComponent)), i1.ɵdid(4, 114688, null, 0, i5.SidebarComponent, [i1.Renderer2, i6.AuthenticationService], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "app-logout", [], null, null, null, i7.View_LogoutFormComponent_0, i7.RenderType_LogoutFormComponent)), i1.ɵdid(6, 114688, null, 0, i8.LogoutFormComponent, [i6.AuthenticationService, i9.EmployeeHttpService], null, null), (_l()(), i1.ɵeld(7, 0, null, null, 6, "div", [["class", "content-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 5, "div", [["class", "container-full"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 4, "section", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 3, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 2, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(13, 212992, null, 0, i10.RouterOutlet, [i10.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 2, 0); _ck(_v, 4, 0); _ck(_v, 6, 0); _ck(_v, 13, 0); }, null); }
export function View_AppLayoutComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dashboard", [], null, null, null, View_AppLayoutComponent_0, RenderType_AppLayoutComponent)), i1.ɵdid(1, 114688, null, 0, i11.AppLayoutComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppLayoutComponentNgFactory = i1.ɵccf("app-dashboard", i11.AppLayoutComponent, View_AppLayoutComponent_Host_0, {}, {}, []);
export { AppLayoutComponentNgFactory as AppLayoutComponentNgFactory };
