<a>
  <li class="header">
    <ng-content></ng-content>
    <div class="float-right mr-1">
      <ng-container *ngIf="expand">
        <mat-icon>expand_less</mat-icon>
      </ng-container>
      <ng-container *ngIf="!expand">
        <mat-icon>expand_more</mat-icon>
      </ng-container>
    </div>
    <div class="clearfix"></div>
  </li>
</a>

