import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthenticatedGuard} from './middlewares/authentication/guards/authenticated.guard';
import {AppLayoutComponent} from './layouts/app-layout/app-layout.component';
import {LoginLayoutComponent} from './layouts/login-layout/login-layout.component';
import {DashboardPageComponent} from './layouts/app-layout/components/pages/dashboard-page/dashboard-page.component';
import {AuthorizationGuard} from 'src/app/middlewares/authentication/guards/authorization.guard';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthenticatedGuard],
    component: AppLayoutComponent,
    children: [
      {
        path: '',
        component: DashboardPageComponent,
      },
      {
        path: 'production',
        data: {permission: ['READ_PRODUCT']},
        canLoad: [AuthorizationGuard],
        loadChildren: () => import('./bundles/auditoria/auditoria.module').then((m) => m.AuditoriaModule),
      },
      {
        path: 'industria',
        loadChildren: () => import('./bundles/industria/industria.module').then(m => m.IndustriaModule)
      },
      {
        path: 'resources',
        loadChildren: () => import('./bundles/erp/erp.module').then(m => m.ErpModule)
      },
      {
        path: 'silicie',
        data: {permission: ['READ_SILICIE']},
        canLoad: [AuthorizationGuard],
        loadChildren: () => import('./bundles/silicie/silicie.module').then(m => m.SilicieModule)
      },
      {
        path: 'bi',
        data: {permission: ['READ_BI']},
        canLoad: [AuthorizationGuard],
        loadChildren: () => import('./bundles/bi/bi.module').then(m => m.BiModule)
      }
    ]
  },
  {
    path: 'login',
    component: LoginLayoutComponent,
  },
  {
    path: '**',
    redirectTo: '/'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
