import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of, throwError} from 'rxjs';
import {catchError, concatMap, map, switchMap, take, tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {AuthenticationHttpService} from './http/authentication-http.service';
import {Authentication, IAuthentication} from '../models/authentication.model';
import jwtDecode from 'jwt-decode';
import {IUser} from '../models/user.model';
import {INewPassword} from '../models/newPassword.model';
import {IAccess} from '../models/access.model';
import {IEmployee} from '../../../bundles/erp/bundles/employee/models/employee.model';
import {EmployeeHttpService} from '../../../bundles/erp/bundles/employee/services/http/employee-http.service';
import {HttpErrorResponse} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  authentication = new BehaviorSubject<IAuthentication>(null);
  currentEmployee = new BehaviorSubject<IEmployee>(null);

  constructor(private authenticationHttpService: AuthenticationHttpService,
              private employeeHttpService: EmployeeHttpService,
              private router: Router) {
  }

  private static extractJwtTokenData(authentication: IAuthentication): IAuthentication {
    const jwtObject = jwtDecode(authentication.access_id_token.replace('Bearer ', '')) as any;
    authentication = {
      ...authentication, ...{
        access_user_permissions: jwtObject.scopes,
        access_user_roles: jwtObject.roles
      }
    };
    return authentication;
  }

  login(email: string, password: string): Observable<any> {
    return this.authenticationHttpService.login(email, password).pipe(
      tap((response) => {
        this.setAuthentication(response);
        localStorage.setItem('authentication', JSON.stringify(response));
      }),
      switchMap((response) => this.hasPermission(['READ_WEB'])),
      take(1),
      tap(hasPermission => {
        if (!hasPermission) {
          this.logout();
          throw new Error('El usuario no tiene permisos para acceder a la web');
        }
      })
    );
  }

  autoLogin() {
    const authentication: IAuthentication = JSON.parse(localStorage.getItem('authentication'));
    if (this.isLoggedIn()) {
      this.setAuthentication(authentication);
    } else {
      this.logout();
    }
  }

  logout(): void {
    if (this.authentication.getValue()) {
      this.authenticationHttpService.logout(this.authentication.getValue().session_token).subscribe();
      this.authentication.next(null);
    }
    localStorage.removeItem('authentication');
    this.router.navigate(['login']);
  }

  isLoggedIn(): boolean {
    const authentication: IAuthentication = JSON.parse(localStorage.getItem('authentication')) || new Authentication();
    return !!authentication.session_token || Date.now() < new Date(authentication.session_expiration).getTime();
  }

  createUser(user: IUser): Observable<IUser> {
    return this.authenticationHttpService.createUser(user);
  }

  lockUser(userId: number): Observable<{}> {
    return this.authenticationHttpService.lockUser(userId);
  }

  unlockUser(userId: number): Observable<{}> {
    return this.authenticationHttpService.unlockUser(userId);
  }

  updatePassword(userId: number, newPassword: INewPassword) {
    return this.authenticationHttpService.updatePassword(userId, newPassword);
  }

  queryUsers(params?: any): Observable<any> {
    return this.authenticationHttpService.queryUsers(params);
  }

  assignUserRoles(userId: number, roleIds: number[]) {
    return this.authenticationHttpService.queryUsers({id: userId}).pipe(
      map(page => page.content[0].roles),
      concatMap((currentRoleIds: number[]) => {
        let $observable = of(true);
        if (currentRoleIds.length) {
          $observable = this.authenticationHttpService.removeUserRoles(userId, currentRoleIds);
        }
        return $observable;
      }),
      concatMap(() => this.authenticationHttpService.addUserRoles(userId, roleIds))
    );
  }

  refreshAccess(): Observable<IAccess> {
    let authentication: IAuthentication = JSON.parse(localStorage.getItem('authentication'));
    return this.authenticationHttpService.updateAccess(authentication).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401 || error.status === 400 || error.status === 500) {
          this.logout();
        }
        return throwError(error);
      })
    ).pipe(
      tap(response => {
        authentication = {
          ...authentication,
          ...{
            access_id_token: response.id_token,
            access_issued_at: response.issued_at,
            access_expiration: response.expiration,
          }
        };
        this.setAuthentication(authentication);
        localStorage.setItem('authentication', JSON.stringify(authentication));
      }));
  }

  isAccessExpired() {
    const authentication: IAuthentication = JSON.parse(localStorage.getItem('authentication')) || new Authentication();
    return !authentication.access_id_token || Date.now() > new Date(authentication.access_expiration).getTime();
  }

  queryRoles(params?: any): Observable<any> {
    return this.authenticationHttpService.queryRoles(params);
  }

  hasPermission(permissions: string[]): Observable<boolean> {
    return this.authentication.pipe(
      map(authentication => {
        if (authentication && authentication.access_user_permissions) {
          for (const permission of permissions) {
            for (const userPermission of authentication.access_user_permissions) {
              if (permission.indexOf(userPermission) === 0) {
                return true;
              }
            }
          }
        }
        return false;
      })
    );
  }

  hasRole(roles: string[]): Observable<boolean> {
    return this.authentication.pipe(
      map(authentication => {
        if (authentication && authentication.access_user_roles) {
          let hasRole = false;
          for (const role of roles) {
            if (authentication.access_user_roles.indexOf(role) !== -1) {
              hasRole = true;
            }
          }
          return hasRole;
        }
      })
    );
  }

  private setAuthentication(response: IAuthentication) {
    response = AuthenticationService.extractJwtTokenData(response);
    this.authentication.next(response);
    this.employeeHttpService.findByAuthenticationUserId(response.session_user_id).subscribe(
      employee => this.currentEmployee.next(employee)
    );
  }
}
